import { Button } from "@components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/ui/form";
import { Input } from "@components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { $sendEvent } from "@lib/analyticsUtils";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  email: z.string().email(),
});

export const MailingListForm: React.FC<{ partnerName: string }> = ({
  partnerName,
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const onSubmit = (data: z.infer<typeof formSchema>) => {
    $sendEvent("MailingListSignup", { email: data.email });
    return true;
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {form.formState.isSubmitSuccessful ? (
          <h3 className="text-lg font-semibold text-center">
            Thank you for signing up!
          </h3>
        ) : (
          <>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-primary-foreground">
                    <p className="mb-4">
                      {partnerName} is here to empower you in your financial
                      journey.
                    </p>
                    <p className="mb-2">To stay connected, enter your email</p>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder="Email address"
                      className="w-full p-2 rounded text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full mt-4">
              Submit
            </Button>
          </>
        )}
      </form>
    </Form>
  );
};
